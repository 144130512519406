import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Social from '../components/Social';
import '../assets/sass/_masthead.scss';
import ContactText from '../components/otherlanguage/ContactText';
import TermsHeader from '../components/header/TermsHeader';
import TermsText from '../components/otherlanguage/TermsText';
import TermsAccordionTitle1 from '../components/otherlanguage/terms/TermsAccordionTitle1';
import TermsAccordionContant1 from '../components/otherlanguage/terms/TermsAccordionContant1';
import Accordion from 'react-bootstrap/Accordion';
import '../assets/sass/_accordion-overwrite.scss';

const terms = () => (
  <Layout>
    <TermsHeader />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="my-auto">
            <div className="header-content mx-auto">
              <section className="terms" id="terms">
                <div className="pagetitle">
                  <TermsText/>
                </div>
                <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <TermsAccordionTitle1/>
                  </Accordion.Header>
                    <Accordion.Body>
                      <TermsAccordionContant1/>
                    </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
              </section>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          <ContactText/>
        </h2>
        <Social />
      </div>
    </section>
    <Footer />
  </Layout>
);

export default terms;
